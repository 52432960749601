import { baseUrl, Token } from "../Utils/constants";
import CallApi from "../Utils/callApi";

const updateLocationInPayload = (parameters) => {
  if (parameters?.location) {
    const [city, state] = parameters.location
      .split(",")
      .map((item) => item.trim());
    return {
      ...parameters,
      job_city: city || "",
      job_state: state || "",
      location: undefined,
    };
  }
  return parameters;
};
export const fetchActiveJob = (page, parameters) => {
  return new Promise(async (resolve, reject) => {
    try {
      const updatedParameters = updateLocationInPayload(parameters);
      const url = `${baseUrl}/jobdetails/?page=${page}&search=${updatedParameters?.title}&job_city=${updatedParameters?.job_city}&job_state=${updatedParameters?.job_state}&job_sponsorship=${updatedParameters?.sponsorship}&job_commitment=${updatedParameters?.duration}&job_general_category=${updatedParameters?.department}&job_experience_level=${updatedParameters?.experience}&min_salary=${updatedParameters?.salary}&job_industry=${updatedParameters?.industry}`;
      const apiOptions = {
        method: "GET",
        headers: {
          Authorization: `Token ${Token}`,
        },
      };
      const response = await CallApi(url, apiOptions);
      resolve(response);
    } catch (error) {
      reject(error);
    }
  });
};

export const fetchJobById = (id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const url = `${baseUrl}/jobdetails/${id}`;
      const apiOptions = {
        method: "GET",
        headers: {
          Authorization: `Token ${Token}`,
        },
      };
      const response = await CallApi(url, apiOptions);
      resolve(response);
    } catch (error) {
      reject(error);
    }
  });
};

export const fetchAllCompanyJobs = (page, company) => {
  return new Promise(async (resolve, reject) => {
    try {
      const url = `${baseUrl}/jobdetails/?search=${company}`;
      const apiOptions = {
        method: "GET",
        headers: {
          Authorization: `Token ${Token}`,
        },
      };
      const response = await CallApi(url, apiOptions);
      resolve(response);
    } catch (error) {
      reject(error);
    }
  });
};

export const fetchTaggedJobs = (page, tag) => {
  return new Promise(async (resolve, reject) => {
    try {
      const url = `${baseUrl}/jobdetails/?job_isFlagged=false&page=${page}&job_description=${tag}`;
      const apiOptions = {
        method: "GET",
        headers: {
          Authorization: `Token ${Token}`,
        },
      };
      const response = await CallApi(url, apiOptions);
      resolve(response);
    } catch (error) {
      reject(error);
    }
  });
};

export const fetchFeaturedJobs = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const url = `${baseUrl}/jobdetails?job_isFeatured=true&job_isFlagged=false`;
      const apiOptions = {
        method: "GET",
        headers: {
          Authorization: `Token ${Token}`,
        },
      };
      const response = await CallApi(url, apiOptions);
      resolve(response);
    } catch (error) {
      reject(error);
    }
  });
};

export const SaveJobOnBackend = (job_id) => {
  return new Promise(async (resolve, reject) => {
    try {
      const url = `${baseUrl}/jobdetails/${job_id}/save/`;
      const apiOptions = {
        method: "PATCH",
        headers: {
          Authorization: `Token ${Token}`,
        },
      };
      const response = await CallApi(url, apiOptions);
      resolve(response);
    } catch (error) {
      reject(error);
    }
  });
};
