import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import BlogCard from "./BlogCard/BlogCard";
import { Typography, Box } from "@mui/material";

const BlogList = () => {
  const [blogs, setBlogs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [subjects, setSubjects] = useState([]);
  const BASE_URL = "https://kazisai.us";

  useEffect(() => {
    // Fetch blog posts from API
    const fetchBlogs = async () => {
      try {
        const response = await axios.get(
          "https://kazisai.us/api/blogs?populate=*"
        );
        const sortedBlogs = response.data.data.sort(
          (a, b) => new Date(b.publishedAt) - new Date(a.publishedAt)
        );
        setBlogs(sortedBlogs);
        const uniqueSubjects = Array.from(
          new Set(sortedBlogs.map((blog) => blog.Subject || "General"))
        );
        setSubjects(uniqueSubjects);
      } catch (error) {
        console.error("Error fetching blogs", error);
      } finally {
        setLoading(false); // Set loading to false once data is fetched
      }
    };

    fetchBlogs();
  }, []);

  const mainStory = blogs.length ? blogs.find((blog) => blog.IsFeatured) : null;
  // Loading Skeleton Component
  const LoadingSkeleton = () => (
    <div className="animate-pulse">
      <div className="bg-gray-200 h-10 w-1/2 mb-4"></div>
      <div className="bg-gray-300 h-64 mb-4"></div>
      <div className="bg-gray-200 h-6 w-3/4 mb-4"></div>
      <div className="bg-gray-200 h-6 w-1/2"></div>
    </div>
  );

  return (
    <div className="container mx-auto p-4">
      {/* Disclaimer */}
      <Box
        sx={{
          backgroundColor: "#164ED4",
          color: "white",
          padding: "8px 16px",
          borderRadius: "8px",
          textAlign: "center",
          margin: "0 auto",
        }}
        role="alert"
      >
        <Typography
          variant="subtitle1"
          fontWeight="bold"
          sx={{ marginBottom: "8px" }}
        >
          Disclaimer:
        </Typography>
        <Typography
          variant="body1"
          fontWeight="normal"
          sx={{ marginBottom: "8px" }}
        >
          These articles are fetched from publicly available sources to inform
          readers on topics relevant to H-1B visas, immigration policies, and
          related matters.
        </Typography>
        <Typography
          variant="body1"
          fontWeight="normal"
          sx={{ marginBottom: "8px" }}
        >
          Please note that the views and opinions expressed are solely those of
          the original authors and do NOT represent the official stance or
          endorsement of VisJobs.
        </Typography>
      </Box>

      <div className="grid grid-cols-1 lg:grid-cols-12 gap-4">
        {/* Left Sidebar - Subjects */}
        <div className="col-span-12 lg:col-span-2 hidden lg:flex flex-col justify-between bg-gray-100 p-4 space-y-6 mt-12">
          <ul className="blog-list">
            {subjects.map((subject, index) => (
              <li
                key={index}
                className="hover:text-[#164ED4] mb-2 mt-3 blog-list-item"
              >
                <Link to={`/app/subject/${subject}`} className="block">
                  {subject}
                </Link>
              </li>
            ))}
          </ul>
        </div>

        {/* Main Content and Right Sidebar */}
        <div className="col-span-12 lg:col-span-10 flex flex-col lg:flex-row gap-6">
          {/* Main Content */}
          <div className="w-full lg:w-2/3 space-y-1">
            {loading ? (
              <LoadingSkeleton /> // Render the skeleton while loading
            ) : (
              mainStory && (
                <Link
                  to={`/app/immigration-news/${mainStory.documentId}`}
                  className="block"
                >
                  <h1 className="text-1xl lg:text-4xl font-bold text-black mt-3">
                    {mainStory.Title}
                  </h1>
                  <img
                    src={
                      mainStory.CoverImage && mainStory.CoverImage.length > 0
                        ? `${BASE_URL}${
                            mainStory.CoverImage[0]?.formats?.medium?.url ||
                            mainStory.CoverImage[0]?.url
                          }`
                        : mainStory.MetaTitle // Use MetaTitle as fallback if no cover image
                        ? mainStory.MetaTitle // MetaTitle contains the image URL in this case
                        : "/path/to/default-image.jpg" // Fallback to a default image path
                    }
                    className="w-full h-auto mt-6"
                    alt={mainStory.Title || "Default Alt Text"} // Fallback alt text
                  />
                </Link>
              )
            )}
          </div>

          {/* Right Sidebar - Latest Blogs */}
          <div className="w-full lg:w-1/3 flex flex-col justify-between pl-3 mt-12">
            <div className="flex flex-col space-y-2 mt-12">
              {loading
                ? Array(4)
                    .fill(0)
                    .map((_, index) => (
                      <div key={index} className="animate-pulse">
                        <div className="bg-gray-200 h-6 w-1/2 mb-2"></div>
                        <div className="bg-gray-300 h-8 mb-4"></div>
                        <div className="bg-gray-200 h-4 w-1/3"></div>
                      </div>
                    ))
                : blogs.slice(1, 3).map((blog) => (
                    <Link
                      to={`/app/immigration-news/${blog.documentId}`}
                      key={blog.id}
                      className="block"
                    >
                      <div className="border-b pb-4">
                        <span className="text-[#164ED4] font-semibold text-sm">
                          {blog.Subject || "General"}
                        </span>
                        <h3 className="font-bold text-lg mt-1 blog-subheading">
                          {blog.Title}
                        </h3>
                        <p className="text-gray-600 text-sm blog-paragraph">
                          {blog.Author}
                        </p>
                      </div>
                    </Link>
                  ))}
            </div>
          </div>
        </div>

        {/* Blog Cards spanning across the entire width */}
        <div className="col-span-12 mt-8 space-y-4">
          {loading
            ? Array(3)
                .fill(0)
                .map((_, index) => (
                  <div key={index} className="animate-pulse">
                    <div className="bg-gray-200 h-6 w-1/2 mb-2"></div>
                    <div className="bg-gray-300 h-48 mb-4"></div>
                    <div className="bg-gray-200 h-4 w-3/4 mb-2"></div>
                    <div className="bg-gray-200 h-4 w-1/4"></div>
                  </div>
                ))
            : blogs.slice(3).map((blog) => (
                <Link
                  to={`/app/immigration-news/${blog.documentId}`}
                  key={blog.id}
                  className="block"
                >
                  <BlogCard
                    blog={{
                      Title: blog.Title,
                      Author: blog.Author,
                      MetaDescription: blog.MetaDescription,
                      CoverImage: blog.CoverImage,
                      MetaTitle: blog.MetaTitle,
                      PublishedDate: blog.PublishedDate,
                      Subject: blog.Subject,
                    }}
                  />
                </Link>
              ))}
        </div>
      </div>
    </div>
  );
};

export default BlogList;
