import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import FormStepper from "../../components/formStepper";
import { Button } from "@material-tailwind/react";
import FileUploader from "../../components/fileUploader";
import InitialDialog from "../JobList/InitialDialog";
import PaymentOptionsDialog from "../JobList/PaymentOptionsDialog";
import { toast } from "react-toastify";
import PaymentForm from "../../components/JobApplications/checkoutForm";
import Supabase from "../../Utils/supabase";
import { createTransaction } from "../../supabaseServices/transaction";
import { useDispatch, useSelector } from "react-redux";
import { getUserByEmail } from "../../services/auth";
import { storageRoute } from "../../Utils/constants";
import { addJobsForTrack } from "../../supabaseServices/cart";
import { clearCartAction } from "../../redux/actions/cart";
import { checkoutJobCart } from "../../services/pingApplicationServer";

const CheckoutForm = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const initialPrice = queryParams.get("price")
    ? parseFloat(queryParams.get("price"))
    : 10;

  const [activeStep, setActiveStep] = useState(0);
  const [isLastStep, setIsLastStep] = useState(false);
  const [isFirstStep, setIsFirstStep] = useState(false);
  const [formData, setFormData] = useState({ type: "one-time" });
  const [options, setOptions] = useState({
    mode: "payment",
    amount: initialPrice,
    currency: "usd",
    appearance: {
      automatic_payment_methods: { enabled: true },
    },
  });
  const authState = useSelector((state) => state.auth);
  const cartReducer = useSelector((state) => state.cart);
  const dispatch = useDispatch();

  const handleNext = () => {
    if (formData.type === "one-time" && activeStep === 2) {
      setActiveStep(3);
    } else if (!isLastStep) {
      setActiveStep((cur) => cur + 1);
    }
  };

  const handlePrev = () => !isFirstStep && setActiveStep((cur) => cur - 1);

  const handleChangeFile = (e) => {
    const { name, value, files } = e.target;
    if (files) {
      if (files[0]?.size > 3 * 1024 * 1024) {
        toast.error("File size must be less than 3MB");
        return;
      }
      const fileURL = URL.createObjectURL(files[0]);
      setFormData((prev) => ({
        ...prev,
        [name]: files[0],
        [`${name}_preview`]: fileURL,
      }));
    } else {
      setFormData((prev) => ({ ...prev, [name]: value }));
    }
  };

  const handleTypeSelection = (type) => {
    if (type === "one-time") {
      // Reset to initial one-time payment price
      setOptions((prev) => ({ ...prev, amount: initialPrice }));
      setFormData((prev) => ({
        ...prev,
        type,
        frequency: null,
        title: null,
        price: initialPrice,
        points: null,
        duration: null,
      }));
    } else {
      setFormData((prev) => ({ ...prev, type }));
    }
  };

  const handleSubmitForm = async (payment, subscription_id) => {
    try {
      const user = await getUserByEmail(authState?.user?.email);

      const { data: resumeData, error: resumeError } = await Supabase.storage
        .from("resumes")
        .upload(
          Math.floor(Math.random() * 1000000) + formData?.resume?.name,
          formData.resume
        );
      if (resumeError) throw resumeError;

      const { data: coverLetterData, error: coverLetterError } =
        await Supabase.storage
          .from("cover_letters")
          .upload(
            Math.floor(Math.random() * 1000000) + formData.cover.name,
            formData.cover
          );
      if (coverLetterError) throw coverLetterError;

      const date = new Date();
      date.setMonth(
        formData?.duration === "3 months"
          ? date.getMonth() + 3
          : date.getMonth() + 1
      ); // Add 1 or 3 months to the current date
      const utcDate = date.toISOString();
      const payload = {
        transaction_id: payment?.id,
        payment_status: "completed",
        plan_type: formData?.type,
        points_earned: formData?.points,
        points_used: 0,
        points_expiry_date: utcDate,
        is_active: true,
        user_id: user?.length > 0 ? user[0]?.id : null,
        subscription_id,
      };
      const response = await createTransaction(payload);
      if (response?.status !== 201) {
        toast.error("Failed to add transaction!");
        return;
      }

      const trackPayload = cartReducer.cartJobs.map((job) => ({
        job_id: job?.job_id,
        job_title: job?.job_title,
        user_id: job?.user_id,
        company_name: job?.company_name,
        job_link: job?.job_link,
      }));
      const trackResponse = await addJobsForTrack(trackPayload);

      if (trackResponse?.status !== 201) {
        toast.error("Failed to add transaction!");
        return;
      }

      const serverResponse = await checkoutJobCart(
        authState?.user?.email,
        cartReducer.cartJobs?.map((job) => job?.job_id),
        storageRoute + "/" + resumeData?.fullPath,
        storageRoute + "/" + coverLetterData?.fullPath,
        cartReducer?.cartJobs?.length,
        response.data[0]?.id
      );

      clearCartAction(authState?.user?.user_id, dispatch);

      setFormData({});
      setActiveStep(0);
      toast.success("Payment Successful");
      return true;
    } catch (e) {
      console.error(e);
      toast.error(
        "Your payment could not be completed. Please review your details and try again."
      );
      return false;
    }
  };

  const renderComponent = () => {
    switch (activeStep + 1) {
      case 1:
        return (
          <div>
            <h2 className="text-xl font-bold mb-4">File Uploads</h2>
            <div className="flex items-center gap-4 mb-8">
              <FileUploader
                preview={formData?.resume_preview}
                handleChange={handleChangeFile}
                title={"Resume"}
              />
              <FileUploader
                preview={formData?.cover_preview}
                handleChange={handleChangeFile}
                title={"Cover Letter"}
              />
            </div>
          </div>
        );
      case 2:
        return (
          <InitialDialog
            type={formData?.type}
            handleType={handleTypeSelection}
          />
        );
      case 3:
        if (formData.type === "subscribe") {
          return (
            <PaymentOptionsDialog
              onBack={handlePrev}
              planType={formData?.type || ""}
              onClose={(details) => {
                setOptions({ ...options, amount: +details?.price });
                setFormData({
                  ...formData,
                  frequency: details?.frequency,
                  title: details?.title,
                  price: details?.price,
                  points: details?.points,
                  duration: details?.type,
                });
                handleNext();
              }}
            />
          );
        }
        return (
          <PaymentForm
            isHome={true}
            amount={options?.amount}
            type={formData?.type}
            onPaymentSuccess={handleSubmitForm}
          />
        );
      case 4:
        return (
          <PaymentForm
            isHome={true}
            amount={options?.amount}
            type={formData?.type}
            onPaymentSuccess={handleSubmitForm}
          />
        );
      default:
        return null;
    }
  };

  return (
    <div>
      <FormStepper
        activeStep={activeStep}
        setActiveStep={setActiveStep}
        setIsFirstStep={setIsFirstStep}
        setIsLastStep={setIsLastStep}
      />
      <div className="p-8">{renderComponent()}</div>
      <div className="p-8 flex justify-between">
        <Button
          onClick={handlePrev}
          disabled={isFirstStep}
          className="bg-[#164ed4]"
        >
          Prev
        </Button>
        {activeStep < 2 && (
          <Button
            onClick={handleNext}
            disabled={
              isLastStep || (activeStep === 0 && formData?.resume == undefined)
            }
            className="bg-[#164ed4]"
          >
            Next
          </Button>
        )}
      </div>
    </div>
  );
};

export default CheckoutForm;
