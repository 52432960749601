import React from "react";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Typography,
} from "@material-tailwind/react";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import "./index.css";
import bannerImg from "../../../../assets/images/banner-background-image.png";
import AutoApplyFAQ from "../../../BannerHome/faq";
import CheckIcon from "./checkIcon";
import { useNavigate } from "react-router-dom";
const AutoApplyFeature = () => {
  const navigate = useNavigate();
  return (
    <>
      <div
        className="bannerHome py-[40px] md:py-[80px] px-3"
        style={{
          backgroundImage: `url(${bannerImg})`,
          backgroundSize: "cover",
        }}
      >
        <div className="mx-auto max-w-[1320px] space-y-[40px] md:py-[80px] w-full">
          <section id="auto-apply-section">
            <div className="heading text-center">
              <Typography
                variant="h1"
                className="text-2xl md:text-4xl lg:text-5xl leading-tight md:leading-tight lg:leading-tight text-[#1D1F2C]"
              >
                <span className="text-[#164ED4]"> We will apply</span> to jobs
                for you
              </Typography>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
              <Card className="mt-6">
                <CardBody className="h-full">
                  <Typography variant="h5" color="blue-gray" className="mb-2">
                    <CheckCircleIcon sx={{ color: "#4CAF50" }} /> Higher chances
                    of interviews
                  </Typography>
                  <Typography variant="body2">
                    We manually fill out every application on your behalf,
                    pulling out all the stops to ensure you stand out as the
                    best among the pool of applicants.
                  </Typography>
                </CardBody>
              </Card>
              <Card className="mt-6">
                <CardBody className="h-full">
                  <Typography variant="h5" color="blue-gray" className="mb-2">
                    <CheckCircleIcon sx={{ color: "#4CAF50" }} /> Save your time
                  </Typography>
                  <Typography variant="body2">
                    On a time crunch? Let us handle the entire application
                    process from <span className="fancy-underline">start</span>{" "}
                    to <span className="fancy-underline">finish</span>, allowing
                    you to focus on what truly matters.
                  </Typography>
                </CardBody>
              </Card>
              <Card className="mt-6">
                <CardBody className="h-full">
                  <Typography variant="h5" color="blue-gray" className="mb-2">
                    <CheckCircleIcon sx={{ color: "#4CAF50" }} /> Beat ATS
                    filters
                  </Typography>
                  <Typography variant="body2">
                    We{" "}
                    <span className="text-[#164ED4]">
                      streamline your applications
                    </span>{" "}
                    and{" "}
                    <span className="text-[#164ED4]">
                      manually verify every single application{" "}
                    </span>
                    to pass Application Tracking System (ATS) filters,
                    increasing your chances of landing interviews
                  </Typography>
                </CardBody>
              </Card>
            </div>

            <div className="flex flex-col items-center justify-center space-y-4">
              <div className="heading text-center">
                <Typography
                  variant="h3"
                  className="text-2xm md:text-4xl lg:text-5xl leading-tight md:leading-tight lg:leading-tight text-[#1D1F2C] mb-4 mt-10"
                >
                  <span className="text-[#164ED4]">
                    {" "}
                    AutoApply is Ideal for you if{" "}
                  </span>
                </Typography>
                <div className="max-w-4xl mt-12">
                  <Typography variant="h6" align="center" gutterBottom>
                    <div>
                      <ul className="list-disc list-inside text-left space-y-6">
                        <li>
                          You want to save time by having your own job hunter.
                          <Typography
                            variant="body2"
                            align="left"
                            gutterBottom
                            className="mt-2"
                          >
                            <blockquote>
                              Instead of spending hours searching and applying
                              for jobs yourself, we can handle the entire
                              process, allowing you to focus on other priorities
                              while we work behind the scenes.
                            </blockquote>
                          </Typography>
                        </li>
                        <li>
                          You’re actively seeking new job opportunities and need
                          consistent help.
                          <Typography
                            variant="body2"
                            align="left"
                            gutterBottom
                            className="mt-2"
                          >
                            <blockquote>
                              If you’re serious about finding a new role, our
                              service ensures a steady stream of job
                              applications tailored to your preferences,
                              increasing your chances of landing a great
                              position.
                            </blockquote>
                          </Typography>
                        </li>
                        <li>
                          You prefer a hands-off approach and want us to handle
                          job research and applications.
                          <Typography
                            variant="body2"
                            align="left"
                            gutterBottom
                            className="mt-2"
                          >
                            <blockquote>
                              Let us take over the legwork of researching and
                              applying to jobs. We’ll manage the entire process,
                              so you don’t have to lift a finger.
                            </blockquote>
                          </Typography>
                        </li>
                        <li>
                          You have specific job targets but prefer the
                          convenience of having someone apply on your behalf.
                          <Typography
                            variant="body2"
                            align="left"
                            gutterBottom
                            className="mt-2"
                          >
                            <blockquote>
                              If you know exactly which companies or roles
                              you're aiming for, we’ll apply to those positions
                              on your behalf, ensuring your job search stays
                              targeted and efficient.
                            </blockquote>
                          </Typography>
                        </li>
                        <li>
                          You’re looking for a flexible solution, whether
                          applying to a few or many jobs.
                          <Typography
                            variant="body2"
                            align="left"
                            gutterBottom
                            className="mt-2"
                          >
                            <blockquote>
                              Whether you need a small number of applications or
                              a higher volume, our service can adapt to your
                              needs, giving you control over your job search
                              strategy.
                            </blockquote>
                          </Typography>
                        </li>
                        <li>
                          You want to increase your chances by applying to
                          multiple jobs every month without the hassle.
                          <Typography
                            variant="body2"
                            align="left"
                            gutterBottom
                            className="mt-2"
                          >
                            <blockquote>
                              Maximize your opportunities by having us apply to
                              multiple positions each month. This consistent
                              approach will help increase your chances of
                              landing interviews.
                            </blockquote>
                          </Typography>
                        </li>
                        <li>
                          You’re planning long-term job searches and prefer a
                          subscription model for ongoing support.
                          <Typography
                            variant="body2"
                            align="left"
                            gutterBottom
                            className="mt-2"
                          >
                            <blockquote>
                              If you're looking for sustained job application
                              support over a longer period, our subscription
                              service ensures continuous applications without
                              interruption.
                            </blockquote>
                          </Typography>
                        </li>
                      </ul>
                    </div>
                  </Typography>
                </div>
              </div>
            </div>

            <div className="flex flex-col items-center justify-center space-y-4">
              <div className="heading text-center">
                <Typography
                  variant="h3"
                  className="text-2xm md:text-4xl lg:text-5xl leading-tight md:leading-tight lg:leading-tight text-[#1D1F2C] mb-4 mt-10"
                >
                  <span className="text-[#164ED4]"> How It Works </span>
                </Typography>
                <div className="max-w-4xl mt-12">
                  <Typography variant="h6" align="center" gutterBottom>
                    <div>
                      <ul className="list-disc list-inside text-left space-y-6">
                        <li>
                          Select a plan tailored to your job search goals.
                          <Typography
                            variant="body2"
                            align="left"
                            gutterBottom
                            className="mt-2"
                          >
                            <blockquote>
                              Choose a plan based on the number of jobs you want
                              us to apply to. The higher the plan, the more
                              applications we’ll send.
                            </blockquote>
                          </Typography>
                        </li>
                        <li>
                          Earn job credits that power your applications.
                          <Typography
                            variant="body2"
                            align="left"
                            gutterBottom
                            className="mt-2"
                          >
                            <blockquote>
                              Once you pay for a plan, you will earn job
                              credits. Each job credit represents one job
                              application. Your credits will be tracked and
                              decrease as we apply to jobs. Unused credits
                              expire after the subscription period.
                            </blockquote>
                          </Typography>
                        </li>
                        <li>
                          Fill out a short form to customize your job search.
                          <Typography
                            variant="body2"
                            align="left"
                            gutterBottom
                            className="mt-2"
                          >
                            <blockquote>
                              Share your job preferences, and for Premium and
                              Gold users, we’ll also revamp your resume and
                              craft compelling cover letters to help you stand
                              out.
                            </blockquote>
                          </Typography>
                        </li>
                        <li>
                          We apply to jobs for you as soon as they’re posted.
                          <Typography
                            variant="body2"
                            align="left"
                            gutterBottom
                            className="mt-2"
                          >
                            <blockquote>
                              We monitor new job opportunities and apply on your
                              behalf, keeping your job search active without the
                              hassle of manual applications.
                            </blockquote>
                          </Typography>
                        </li>
                        <li>
                          Track your job applications in real-time.
                          <Typography
                            variant="body2"
                            align="left"
                            gutterBottom
                            className="mt-2"
                          >
                            <blockquote>
                              View the jobs we’ve applied to by visiting your
                              profile and checking the "Auto-Applied" jobs
                              section.
                            </blockquote>
                          </Typography>
                        </li>
                      </ul>
                    </div>
                  </Typography>
                </div>
              </div>
            </div>

            <div className="flex flex-col justify-center items-center space-y-3 py-2">
              <div className="heading text-center">
                <Typography
                  variant="h2"
                  className="text-2xl md:text-4xl lg:text-5xl leading-tight md:leading-tight lg:leading-tight text-[#1D1F2C] mt-10"
                >
                  Our goal is
                  <span className="text-[#164ED4]">
                    {" "}
                    to land you an interview
                  </span>
                </Typography>
              </div>

              <div className="grid grid-cols-1 md:grid-cols-3 items-center gap-4 pt-10">
                <Card
                  color="white"
                  variant="gradient"
                  className="w-full max-w-[20rem] p-8 "
                >
                  <CardHeader
                    floated={false}
                    shadow={false}
                    color="transparent"
                    className="m-0 mb-8 rounded-none border-b border-white/10 pb-8 text-center"
                  >
                    <Typography
                      variant="small"
                      color="black"
                      className="font-normal uppercase"
                    >
                      Basic
                    </Typography>
                    <Typography
                      variant="h3"
                      color="black"
                      className="mt-6 flex justify-center gap-1 text-[3rem] font-normal"
                    >
                      <span className="mt-2 text-[2rem]">$</span>29.99{" "}
                      <span className="self-end text-[1rem]">/one time</span>
                    </Typography>
                  </CardHeader>
                  <CardBody className="p-0">
                    <ul className="flex flex-col gap-4">
                      <li className="flex items-center gap-4">
                        <span className="rounded-full border border-black/20 bg-black/20 p-1">
                          <CheckIcon />
                        </span>
                        <Typography className="font-normal">
                          5 job applications
                        </Typography>
                      </li>
                      <li className="flex items-center gap-4">
                        <span className="rounded-full border border-black/20 bg-black/20 p-1">
                          <CheckIcon />
                        </span>
                        <Typography className="font-normal">
                          Basic application optimization
                        </Typography>
                      </li>
                      <li className="flex items-center gap-4">
                        <span className="rounded-full border border-black/20 bg-black/20 p-1">
                          <CheckIcon />
                        </span>
                        <Typography className="font-normal">
                          Standard support
                        </Typography>
                      </li>
                    </ul>
                  </CardBody>
                  <CardFooter className="mt-12 p-0">
                    <Button
                      size="lg"
                      color="black"
                      className="hover:scale-[1.02] focus:scale-[1.02] active:scale-100"
                      ripple={false}
                      fullWidth={true}
                      onClick={() =>
                        navigate("/app/autoApplyForm", {
                          state: { jobs: 5, price: 30 },
                        })
                      }
                    >
                      Buy Now
                    </Button>
                  </CardFooter>
                </Card>
                <Card
                  color="white"
                  variant="gradient"
                  className="w-full max-w-[20rem] p-8 "
                >
                  <CardHeader
                    floated={false}
                    shadow={false}
                    color="transparent"
                    className="m-0 mb-8 rounded-none border-b border-white/10 pb-8 text-center"
                  >
                    <Typography
                      variant="small"
                      color="black"
                      className="font-normal uppercase"
                    >
                      Premium
                    </Typography>
                    <Typography
                      variant="h3"
                      color="black"
                      className="mt-6 flex justify-center gap-1 text-[3rem] font-normal"
                    >
                      <span className="mt-2 text-[2rem]">$</span>59.99{" "}
                      <span className="self-end text-[1rem]">/monthly</span>
                    </Typography>
                  </CardHeader>
                  <CardBody className="p-0">
                    <ul className="flex flex-col gap-4">
                      <li className="flex items-center gap-4">
                        <span className="rounded-full border border-black/20 bg-black/20 p-1">
                          <CheckIcon />
                        </span>
                        <Typography className="font-normal">
                          10 job applications
                        </Typography>
                      </li>
                      <li className="flex items-center gap-4">
                        <span className="rounded-full border border-black/20 bg-black/20 p-1">
                          <CheckIcon />
                        </span>
                        <Typography className="font-normal">
                          Advanced application optimization
                        </Typography>
                      </li>
                      <li className="flex items-center gap-4">
                        <span className="rounded-full border border-black/20 bg-black/20 p-1">
                          <CheckIcon />
                        </span>
                        <Typography className="font-normal">
                          Priority support
                        </Typography>
                      </li>
                    </ul>
                  </CardBody>
                  <CardFooter className="mt-12 p-0">
                    <Button
                      size="lg"
                      color="black"
                      className="hover:scale-[1.02] focus:scale-[1.02] active:scale-100"
                      ripple={false}
                      fullWidth={true}
                      onClick={() =>
                        navigate("/app/autoApplyForm", {
                          state: { jobs: 10, price: 60 },
                        })
                      }
                    >
                      Buy Now
                    </Button>
                  </CardFooter>
                </Card>
                <Card
                  color="white"
                  variant="gradient"
                  className="w-full max-w-[20rem] p-8 "
                >
                  <CardHeader
                    floated={false}
                    shadow={false}
                    color="transparent"
                    className="m-0 mb-8 rounded-none border-b border-white/10 pb-8 text-center"
                  >
                    <Typography
                      variant="small"
                      color="black"
                      className="font-normal uppercase"
                    >
                      Gold
                    </Typography>
                    <Typography
                      variant="h3"
                      color="black"
                      className="mt-6 flex justify-center gap-1 text-[3rem] font-normal"
                    >
                      <span className="mt-2 text-[2rem]">$</span>99.99{" "}
                      <span className="self-end text-[1rem]">
                        /three months
                      </span>
                    </Typography>
                  </CardHeader>
                  <CardBody className="p-0">
                    <ul className="flex flex-col gap-4">
                      <li className="flex items-center gap-4">
                        <span className="rounded-full border border-black/20 bg-black/20 p-1">
                          <CheckIcon />
                        </span>
                        <Typography className="font-normal">
                          20 job applications
                        </Typography>
                      </li>
                      <li className="flex items-center gap-4">
                        <span className="rounded-full border border-black/20 bg-black/20 p-1">
                          <CheckIcon />
                        </span>
                        <Typography className="font-normal">
                          Comprehensive application optimization
                        </Typography>
                      </li>
                      <li className="flex items-center gap-4">
                        <span className="rounded-full border border-black/20 bg-black/20 p-1">
                          <CheckIcon />
                        </span>
                        <Typography className="font-normal">
                          24/7 premium support
                        </Typography>
                      </li>
                      <li className="flex items-center gap-4">
                        <span className="rounded-full border border-black/20 bg-black/20 p-1">
                          <CheckIcon />
                        </span>
                        <Typography className="font-normal">
                          Job research included
                        </Typography>
                      </li>
                    </ul>
                  </CardBody>
                  <CardFooter className="mt-12 p-0">
                    <Button
                      size="lg"
                      color="black"
                      className="hover:scale-[1.02] focus:scale-[1.02] active:scale-100"
                      ripple={false}
                      fullWidth={true}
                      onClick={() =>
                        navigate("/app/autoApplyForm", {
                          state: { jobs: 20, price: 100 },
                        })
                      }
                    >
                      Buy Now
                    </Button>
                  </CardFooter>
                </Card>
              </div>
            </div>

            <div className="flex flex-col items-center justify-center space-y-4">
              <div className="heading text-center">
                <Typography
                  variant="h3"
                  className="text-2xm md:text-4xl lg:text-5xl leading-tight md:leading-tight lg:leading-tight text-[#1D1F2C] mb-4 ,mt-12"
                >
                  <span className="text-[#164ED4]">
                    {" "}
                    Already Know Which Jobs You Want?{" "}
                  </span>
                </Typography>
                <div className="max-w-4xl mt-12">
                  <Typography variant="h6" align="center" gutterBottom>
                    <div>
                      If you have specific job listings or companies you're
                      targeting, the Job Cart option is designed just for you.
                      This feature lets you take control of your job search by
                      selecting the exact positions you want us to apply for.
                    </div>
                  </Typography>
                  <Typography
                    variant="body2"
                    align="center"
                    gutterBottom
                    className="text-center italic"
                  >
                    <blockquote className="mt-6">
                      If you’ve identified specific roles within our platform,
                      simply add those job listings to your cart. We’ll handle
                      the entire application process, ensuring your materials
                      are submitted directly to the positions you’ve selected.
                      <span
                        className="text-[#164ED4] underline cursor-pointer"
                        onClick={() => navigate("/app/cart")}
                      >
                        Learn more about the Job Cart option and how to get
                        started here.
                      </span>
                    </blockquote>
                  </Typography>
                </div>
              </div>
            </div>

            <div className="flex flex-col items-center justify-center space-y-4">
              <div className="heading text-center">
                <Typography
                  variant="h1"
                  className="text-2xl md:text-4xl lg:text-5xl leading-tight md:leading-tight lg:leading-tight text-[#1D1F2C] mt-10"
                >
                  AutoApply <span className="text-[#164ED4]">FAQs </span>
                </Typography>
              </div>
              <AutoApplyFAQ />
            </div>
          </section>
        </div>
      </div>
    </>
  );
};

export default AutoApplyFeature;
