import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import CompanySummary from "./CompanySummary";
import Search from "./CompanySummary/Header/companySearchBar";
import CompanyHeader from "./CompanySummary/Header/companyHeader";
import JobSection from "./CompanyJobSection";
import { searchCompany } from "../../services/searchCompany";
import { fetchcompanySummary } from "../../services/fetchCompanySummary";
import JobCardSkeleton from "../JobCard/JobSkeleton";
import SkeletonCard from "./CompanySummary/Cards/cardsSkeleton";
import WageInformation from "./WageInformation";
import {
  getLatestYearData,
  getYearlyChangeData,
} from "./ChartFunctions/cardDataProcessor";
import JobDistributionCard from "./JobDistribution";
import NoCompanyJobFound from "./NoCompanyJobFound";
import CommentSection from "./CommentSection";
const DEFAULT_COMPANY = { name: "Google LLC", id: 2883 };
const CACHE_KEY = "defaultCompanyData";
const CACHE_EXPIRY_KEY = "defaultCompanyDataExpiry";
const CACHE_DURATION = 24 * 60 * 60 * 1000;

const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

const CompanyStatistic = () => {
  const { companyId } = useParams();
  const [query, setQuery] = useState("");
  const [results, setResults] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [companySummaryData, setCompanySummaryData] = useState(null);
  const [isCompanySummaryLoading, setIsCompanySummaryLoading] = useState(true);
  const [year, setYear] = useState(new Date().getFullYear());
  const [newsData, setNewsData] = useState([]);

  const fetchCompanyData = async (id, year) => {
    const startYear = Math.max(2019, year - 1); // Ensure we don't go below 2019
    const endYear = year;

    setIsCompanySummaryLoading(true);
    try {
      const response = await fetchcompanySummary(id, startYear, endYear);
      const latestYearData = getLatestYearData(response.data);
      const yearlyChangeData = getYearlyChangeData(response.data);

      const companyData = {
        ...response.data,
        latestYearData,
        yearlyChangeData,
        year,
      };

      if (id === DEFAULT_COMPANY.id) {
        localStorage.setItem(CACHE_KEY, JSON.stringify(companyData));
        localStorage.setItem(CACHE_EXPIRY_KEY, Date.now() + CACHE_DURATION);
      }

      setCompanySummaryData(companyData);
    } catch (error) {
    } finally {
      setIsCompanySummaryLoading(false);
    }
  };
  useEffect(() => {
    const fetchNewsData = async () => {
      try {
        const response = await fetch("https://kazisai.us/api/company-trends");
        const data = await response.json();
        setNewsData(data.data);
      } catch (error) {
        console.error("Error fetching news data:", error);
      }
    };
    fetchNewsData();
  }, []);

  useEffect(() => {
    const cachedData = localStorage.getItem(CACHE_KEY);
    const cachedDataExpiry = localStorage.getItem(CACHE_EXPIRY_KEY);

    if (cachedData && cachedDataExpiry && Date.now() < cachedDataExpiry) {
      setCompanySummaryData(JSON.parse(cachedData));
    } else {
      fetchCompanyData(DEFAULT_COMPANY.id, year);
    }
  }, [year]);

  useEffect(() => {
    if (query.length > 2 && !selectedCompany) {
      const delayDebounceFn = setTimeout(async () => {
        setIsLoading(true);
        try {
          const response = await searchCompany(query);
          if (response.data) {
            setResults(response.data);
          } else {
            setResults([]);
          }
        } catch (error) {
          setResults([]);
        } finally {
          setIsLoading(false);
        }
      }, 300); // delay for debounce

      return () => clearTimeout(delayDebounceFn);
    } else {
      setResults([]);
    }
  }, [query, selectedCompany]);

  useEffect(() => {
    if (companyId) {
      fetchCompanyData(companyId, year);
    } else if (!selectedCompany) {
      fetchCompanyData(DEFAULT_COMPANY.id, year);
    }
  }, [companyId, year]);

  useEffect(() => {
    if (selectedCompany) {
      fetchCompanyData(selectedCompany.id, year);
    }
  }, [selectedCompany, year]);

  const handleSelectCompany = (company) => {
    setQuery(company.name);
    setSelectedCompany(company);
    setResults([]);
  };

  const handleInputChange = (e) => {
    const newQuery = e.target.value;
    setQuery(newQuery);
    if (newQuery === "") {
      setSelectedCompany(DEFAULT_COMPANY);
      fetchCompanyData(DEFAULT_COMPANY.id, year);
    } else {
      setSelectedCompany(null); // Clear selected company on input change
    }
  };

  const handleYearChange = (selectedYear) => {
    setYear(parseInt(selectedYear));
  };

  // Fallback to DEFAULT_COMPANY if selectedCompany is null
  const currentCompany = selectedCompany || DEFAULT_COMPANY;
  const capitalizedCompanyName = capitalizeFirstLetter(currentCompany.name);

  return (
    <div className="mx-auto max-w-screen-3xl px-6">
      <div className="mx-auto max-w-[1320px] space-y-[40px] md:py-[80px]">
        <div className="space-y-10">
          <Search
            query={query}
            onInputChange={handleInputChange}
            results={results}
            isLoading={isLoading}
            onSelectCompany={handleSelectCompany}
            containerClassName="max-w-[600px] mx-auto"
            year={year}
            onYearChange={handleYearChange}
          />
          {isCompanySummaryLoading ? (
            <SkeletonCard />
          ) : (
            <CompanyHeader
              companyInfo={currentCompany} // Use the fallback company here
              visaDistribution={companySummaryData?.visa_distribution}
            />
          )}
        </div>
        {isCompanySummaryLoading ? (
          <SkeletonCard />
        ) : (
          <CompanySummary
            companySummaryData={companySummaryData}
            companyInfo={selectedCompany || DEFAULT_COMPANY}
            newsData={newsData}
          />
        )}
        <div className=" ">
          {isCompanySummaryLoading ? (
            <div className="flex flex-wrap">
              {Array.from(new Array(3)).map((_, index) => (
                <div key={index} className="w-full md:w-1/2 lg:w-1/3 p-2">
                  <JobCardSkeleton />
                </div>
              ))}
            </div>
          ) : companySummaryData ? (
            companySummaryData.top_jobs &&
            companySummaryData.top_jobs.length > 0 ? (
              <JobSection
                companyId={companySummaryData.id}
                companyName={capitalizedCompanyName}
                topJobs={companySummaryData.top_jobs}
              />
            ) : (
              <div className="flex flex-col justify-center items-center space-y-4 mt-20 mb-20">
                <NoCompanyJobFound
                  text="No jobs found in our database. Visit"
                  link="/app/autoApply"
                  linkText="our Auto-Apply service"
                />
              </div>
            )
          ) : null}
        </div>
        <div className="flex flex-col lg:flex-row items-center gap-10">
          <div className="w-full lg:w-3/5">
            {isCompanySummaryLoading ? (
              <p>loading</p>
            ) : companySummaryData ? (
              companySummaryData.job_title_distribution && (
                <JobDistributionCard
                  data={companySummaryData.job_title_distribution}
                  companyName={capitalizedCompanyName}
                />
              )
            ) : (
              <div className="flex flex-col justify-center items-center space-y-4 mt-20 mb-20">
                <NoCompanyJobFound
                  text="No jobs found in our database. Visit"
                  link="/app/autoApply"
                  linkText="our Auto-Apply service"
                />
              </div>
            )}
          </div>
          <div className="w-full lg:w-2/5">
            {isCompanySummaryLoading ? (
              <p>loading</p>
            ) : companySummaryData ? (
              companySummaryData.job_title_distribution && (
                <WageInformation
                  data={companySummaryData.wage_information}
                  companyName={capitalizedCompanyName}
                />
              )
            ) : (
              <div className="flex flex-col justify-center items-center space-y-4 mt-20 mb-20">
                <NoCompanyJobFound
                  text="No jobs found in our database. Visit"
                  link="/app/autoApply"
                  linkText="our Auto-Apply service"
                />
              </div>
            )}
          </div>
        </div>
        <div className="flex flex-col lg:flex-row items-center gap-10">
          <CommentSection
            companyName={capitalizedCompanyName}
            companyId={currentCompany.id}
          />
        </div>
      </div>
    </div>
  );
};

export default CompanyStatistic;
