import React from "react";
import VerifiedIcon from "@mui/icons-material/Verified";
import DoNotDisturbOnIcon from "@mui/icons-material/DoNotDisturbOn";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import InfoIcon from "@mui/icons-material/Info";
import Tooltip from "@mui/material/Tooltip";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

const EVerifyStatusIcon = ({ status }) => {
  const getIcon = () => {
    if (status === true) {
      return (
        <VerifiedIcon
          style={{ fontSize: 30, color: "green", marginRight: 5 }}
        />
      );
    } else if (status === false) {
      return (
        <DoNotDisturbOnIcon
          style={{ fontSize: 30, color: "red", marginRight: 5 }}
        />
      );
    } else {
      return (
        <QuestionMarkIcon
          style={{ fontSize: 30, color: "orange", marginRight: 5 }}
        />
      );
    }
  };

  const getStatusText = () => {
    if (status === true) {
      return "Yes";
    } else if (status === false) {
      return "No";
    } else {
      return "Unknown";
    }
  };

  return (
    <Box display="flex" alignItems="center">
      {getIcon()}
      <p className="text-md font-normal text-[#4A4C56]">{getStatusText()}</p>

      {/* <Tooltip
        title="This information is sourced from public sources. To view the latest information visit e-verify.gov"
        arrow
      >
        <InfoIcon style={{ fontSize: 20, color: "gray", marginLeft: 5 }} />
      </Tooltip> */}
    </Box>
  );
};

const EVCard = ({ status }) => {
  return (
    <Card style={{ minWidth: 150, margin: "5px", borderRadius: "10px" }}>
      <CardContent>
        <p className="text-lg font-bold capitalize text-black">
          E-Verify Information{" "}
        </p>
        <Typography variant="body2" color="text.secondary">
          <EVerifyStatusIcon status={status} />
        </Typography>
        <Typography
          variant="caption"
          color="text.secondary"
          display="block"
          mt={1}
          sx={{ fontSize: "0.6rem" }}
        >
          Information may be outdated, visit e-verify.gov for latest data
        </Typography>
      </CardContent>
    </Card>
  );
};

const VisaInfoCard = ({ visaType, totalCases }) => {
  return (
    <Card
      style={{ minWidth: 150, margin: "5px", borderRadius: "10px" }}
      className="flex justify-center items-center"
    >
      <CardContent>
        <p className="text-lg font-bold capitalize text-black">
          {" "}
          {visaType} Cases
        </p>
        <p className="text-md font-bold text-center text-black">{totalCases}</p>
        {/* <Typography variant="h7" color="text.secondary">
          Total Cases: {totalCases}
        </Typography> */}
      </CardContent>
    </Card>
  );
};

const CompanyHeader = ({ companyInfo, visaDistribution }) => {
  const uniqueVisaDistribution = [
    ...new Map(
      visaDistribution.map((item) => [item["visa_class"], item])
    ).values(),
  ];

  return (
    <Box className="flex flex-col space-y-2">
      <Box className="flex items-center">
        <h1 className="text-primary text-2xl lg:text-[40px] capitalize font-bold flex items-center border p-2 border-primary bg-gray-300 rounded-full">
          {companyInfo ? companyInfo.name : "Google LLC"}
        </h1>
      </Box>
      <Box className="flex flex-wrap">
        <EVCard status={companyInfo ? companyInfo.e_verify : null} />
        {uniqueVisaDistribution.map((visa) => (
          <VisaInfoCard
            key={visa.id}
            visaType={visa.visa_class}
            totalCases={visa.total_cases}
          />
        ))}
      </Box>
    </Box>
  );
};

export default CompanyHeader;
