import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import ReactMarkdown from "react-markdown";
import SocialSidebar from "./SocialSidebar"; // Import the SocialSidebar component
import { Skeleton } from "@mui/material";
import "./BlogView.css"; // Custom CSS for the blog view

const BlogView = () => {
  const { id } = useParams();
  const [blog, setBlog] = useState(null);

  useEffect(() => {
    const fetchBlog = async () => {
      try {
        const response = await axios.get(
          `https://kazisai.us/api/blogs/${id}?populate=*`
        );
        setBlog(response.data.data);
      } catch (error) {
        console.error("Error fetching blog", error);
      }
    };

    fetchBlog();
  }, [id]);

  if (!blog) {
    return (
      <div className="blog-view-wrapper">
        <div className="blog-header">
          <Skeleton variant="text" width="60%" height={40} />
          <Skeleton variant="text" width="40%" height={20} />
        </div>

        <div className="image-sidebar-container">
          <Skeleton variant="rectangular" width="100%" height={300} />
          <Skeleton
            variant="text"
            width="30%"
            height={20}
            style={{ marginTop: "10px" }}
          />
        </div>

        <div className="blog-content">
          <Skeleton variant="text" width="100%" height={30} />
          <Skeleton variant="text" width="90%" height={30} />
          <Skeleton variant="text" width="95%" height={30} />
          <Skeleton variant="text" width="85%" height={30} />
          <Skeleton variant="text" width="80%" height={30} />
        </div>

        <div>
          <Skeleton variant="text" width="20%" height={30} />
        </div>
      </div>
    );
  }

  const coverImage =
    blog && blog.CoverImage && blog.CoverImage[0]?.url
      ? `https://kazisai.us${blog.CoverImage[0].url}`
      : blog.MetaTitle
      ? blog.MetaTitle
      : "/path/to/default-image.jpg";

  const blogUrl = `https://kazisai.us/api/blogs/${id}`;
  const shareUrl = `https://www.visjobs.com/app/immigration-news/${id}`;
  return (
    <div className="blog-view-wrapper">
      <div className="blog-header">
        <h1 className="blog-title">{blog.Title}</h1>
        <p className="blog-author">
          <span className="highlight">{blog.Author || "Unknown Author"}</span> /{" "}
          {new Date(blog.publishedAt).toLocaleTimeString()} •{" "}
          {new Date(blog.publishedAt).toLocaleDateString()}
        </p>
      </div>

      <div className="image-sidebar-container">
        {coverImage && (
          <div className="blog-image">
            <img src={coverImage} alt={blog.Title} />
            <p className="image-credits">
              <strong>Image Credits:</strong> {blog.imageCredits || "Unknown"}
            </p>
          </div>
        )}
        <SocialSidebar title={blog.Title} url={shareUrl} />
      </div>

      <div className="blog-content">
        <ReactMarkdown
          components={{
            a: ({ node, ...props }) => (
              <a
                href={props.href}
                target="_blank"
                rel="noopener noreferrer"
                style={{
                  color: "rgb(22, 78, 212)",
                  textDecoration: "none",
                  borderBottom: "1px solid rgb(22, 78, 212)",
                }}
              >
                {props.children}
              </a>
            ),
          }}
        >
          {blog.Content}
        </ReactMarkdown>
      </div>
      <div>
        <a
          href={blog.Tags}
          target="_blank"
          rel="noopener noreferrer"
          style={{ color: "#007BFF", textDecoration: "none" }}
        >
          Source Link
        </a>
      </div>
    </div>
  );
};

export default BlogView;
