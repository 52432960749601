import {
  Button,
  Card,
  Chip,
  Tooltip,
  Typography,
} from "@material-tailwind/react";
import moment from "moment/moment";

const TABLE_HEAD = ["Title", "Company", "Applied On", "Status", "Link"];

const TrackJobsTable = ({ data }) => {
  return (
    <Card className="">
      <table className=" table-auto min-w-max text-left text-xs">
        <thead>
          <tr>
            {TABLE_HEAD.map((head) => (
              <th
                key={head}
                className="border-b border-blue-gray-100 bg-[#E8EDFB] p-4"
              >
                <Typography
                  variant="small"
                  color="#777980"
                  className="font-normal text-[#777980] leading-none opacity-70 text-xs"
                >
                  {head}
                </Typography>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data?.length > 0 &&
            data?.map((transaction, index) => (
              <tr key={index} className="even:bg-[#F6F8FA]">
                <td className="p-4 min-w-[8vw]">
                  <Typography
                    variant="small"
                    className="font-normal text-[#1D1F2C] capitalize text-xs"
                  >
                    {transaction?.job_title?.length > 20 ? (
                      <Tooltip
                        placement="top"
                        className="border border-blue-gray-50 bg-white px-4 py-3 shadow-xl shadow-black/10"
                        content={
                          <div className="text-[#1D1F2C] capitalize text-xs">
                            {transaction?.job_title}
                          </div>
                        }
                      >
                        <span className="cursor-pointer">
                          {transaction?.job_title?.substring(0, 20) + "..."}
                        </span>
                      </Tooltip>
                    ) : (
                      transaction?.job_title
                    )}
                  </Typography>
                </td>
                <td className="p-4 min-w-[8vw]">
                  <Typography
                    variant="small"
                    // color="blue-gray"
                    className="font-normal text-[#1D1F2C] text-xs"
                  >
                    {transaction?.company_name}
                  </Typography>
                </td>

                <td className="p-4 min-w-[8vw]">
                  <Typography
                    variant="small"
                    // color="blue-gray"
                    className="font-normal text-[#1D1F2C] text-xs"
                  >
                    <span className="hidden lg:block">
                      {moment(transaction?.created_at).format("DD-MM-YYYY")}
                    </span>
                    <span className="block lg:hidden">
                      {moment(transaction?.created_at).format("MMM-YY")}
                    </span>
                  </Typography>
                </td>

                <td class="p-4 min-w-[8vw]">
                  <div class="w-max">
                    {
                      <Chip
                        className="capitalize text-xs text-white"
                        color={
                          transaction?.status == "in progress"
                            ? "amber"
                            : "green"
                        }
                        value={transaction?.status}
                      />
                    }
                  </div>
                </td>

                <td className="p-4 min-w-[100px]">
                  <a
                    href={transaction?.job_link}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-blue-600 hover:underline"
                  >
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M5 15L19 1"
                        stroke="rgb(22, 78, 212)"
                        strokeWidth="2"
                      />
                      <path
                        d="M19 1L19 11"
                        stroke="rgb(22, 78, 212)"
                        strokeWidth="2"
                      />
                      <path
                        d="M19 1L9 1"
                        stroke="rgb(22, 78, 212)"
                        strokeWidth="2"
                      />
                    </svg>
                  </a>
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </Card>
  );
};
export default TrackJobsTable;
