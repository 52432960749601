import React, { useState } from "react";
import { Typography, Input, Select, Option } from "@material-tailwind/react";
import "./bannerHome.css"; // Ensure this is correctly pointing to your CSS file
import bannerImg from "../../assets/images/banner-background-image.png";
import google from "../../assets/images/google.svg";
import netflix from "../../assets/images/netflix.svg";
import amazon from "../../assets/images/amazon.svg";
import palantir from "../../assets/images/palantir.svg";
import anthrop from "../../assets/images/anthrop.svg";
import { useNavigate } from "react-router-dom";
import Autocomplete from "./Location2";
import { jobCategories, jobCommitments } from "../../Utils/constants";
import VisIcons from "./Icons/VisIconRed";
import CircleBackground from "./Icons/CircleIcon";
import GreenKeyIcon from "./Icons/GreenKeyIcon";
import YellowIcon from "./Icons/YellowIcon";
import AutoApplyFeature from "../JobApplications/components/AutoApplyLandingPage/mainLandingPage";

const BannerHome = () => {
  const navigate = useNavigate();
  const [parameters, setParameters] = useState({
    title: "",
    location: "",
    experience: "",
    duration: "Full-Time",
    department: "Engineering",
    salary: 30000,
    industry: "Computer Software",
  });

  const handleTitle = (value) => {
    setParameters({ ...parameters, title: value });
  };

  const handleLocation = (value) => {
    setParameters({ ...parameters, location: value });
  };

  const handleDuration = (value) => {
    setParameters({ ...parameters, duration: value });
  };

  return (
    <>
      <div
        className="bannerHome py-[40px] md:py-[80px]"
        style={{ backgroundImage: `url(${bannerImg})` }}
      >
        <div className="container mx-auto px-3">
          <div className="bannerContent text-center">
            <div className="relative">
              <CircleBackground />
              <Typography
                variant="h2"
                className="text-2xl md:text-4xl lg:text-5xl leading-tight md:leading-tight lg:leading-tight text-[#4A4C56]"
              >
                Elevate Your
              </Typography>
              <Typography
                variant="h1"
                className="text-2xl md:text-4xl lg:text-6xl leading-tight md:leading-tight lg:leading-tight text-[#4A4C56] max-w-[1146px] mx-auto"
              >
                <span className="text-[#164ED4]">H-1B/OPT Career</span> Search
              </Typography>
            </div>
            <Typography
              variant="paragraph"
              className="text-gray-600 md:text-2xl max-w-[965px] mx-auto mt-6"
            >
              Are you a job seeker looking for visa-sponsored jobs in the US?
              VisJobs is here to help - your one stop-shop for H-1B/OPT jobs.
            </Typography>
            <VisIcons />
            <div className="relative">
              <div className="absolute top-[50%] left-[0] translate-y-[-50%] z-[-1] hidden md:block">
                <GreenKeyIcon />
              </div>
              <div className="banner-input-wrapper flex justify-between p-3 bg-[#fff] rounded-xl max-w-[742px] mx-auto flex-wrap gap-y-3 md:gap-y-0">
                <div className="relative w-[100%] md:w-[50%]">
                  <div className="absolute left-0 top-[50%] translate-y-[-50%]">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="28"
                      height="29"
                      viewBox="0 0 28 29"
                      fill="none"
                    >
                      <path
                        d="M25.1184 24.3817L20.6372 19.9005C22.1352 18.1237 23.0417 15.8335 23.0417 13.3333C23.0417 7.70417 18.4625 3.125 12.8333 3.125C7.20417 3.125 2.625 7.70417 2.625 13.3333C2.625 18.9625 7.20417 23.5417 12.8333 23.5417C15.3335 23.5417 17.6237 22.6352 19.4005 21.1372L23.8816 25.6183C24.052 25.7887 24.276 25.875 24.5 25.875C24.724 25.875 24.948 25.7898 25.1184 25.6183C25.4602 25.2777 25.4602 24.7235 25.1184 24.3817ZM4.375 13.3333C4.375 8.669 8.169 4.875 12.8333 4.875C17.4977 4.875 21.2917 8.669 21.2917 13.3333C21.2917 17.9977 17.4977 21.7917 12.8333 21.7917C8.169 21.7917 4.375 17.9977 4.375 13.3333Z"
                        fill="#4A4C56"
                      />
                    </svg>
                  </div>
                  <Input
                    placeholder="Search job description, job name or company name"
                    className="pl-10 border-none rounded-none placeholder:opacity-100"
                    labelProps={{
                      className: "before:content-none after:content-none",
                    }}
                    value={parameters?.title}
                    onChange={(e) => handleTitle(e.currentTarget.value)}
                  />
                </div>
                <Autocomplete
                  isHome={true}
                  onLocationChange={handleLocation}
                  fetchResult={() => {}}
                />
              </div>
              <div className="toggle-btn-container flex-wrap">
                <div className="relative">
                  <Select
                    variant="static"
                    placeholder="Select Duration"
                    onChange={(e) => handleDuration(e)}
                    value={parameters?.duration}
                    className="jobDurationList"
                  >
                    {jobCommitments?.map((commitment, index) => (
                      <Option value={commitment?.value} key={index}>
                        {commitment?.label}
                      </Option>
                    ))}
                  </Select>
                </div>

                <div className="relative">
                  <Select
                    variant="static"
                    value={parameters?.department}
                    onChange={(value) =>
                      setParameters({ ...parameters, department: value })
                    }
                    placeholder="Select Field"
                    className="jobDurationList"
                  >
                    {jobCategories?.map((category, index) => (
                      <Option value={category?.value} key={index}>
                        {category?.label}
                      </Option>
                    ))}
                  </Select>
                </div>
              </div>
              <YellowIcon />
            </div>
            <div className="flex justify-center space-x-4 my-[24px] md:my-[48px]">
              <button
                className="flex justify-center items-center gap-[8px] select-none rounded-lg bg-[#164ED4] p-[16px] max-w-[222px] w-full font-sans text-xs font-bold uppercase text-white shadow-md shadow-blue-500/20 transition-all hover:shadow-lg hover:shadow-blue-500/40 focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
                type="button"
                onClick={() =>
                  navigate("/app/job-list", {
                    state: { jobParameters: parameters },
                  })
                }
              >
                Find Jobs
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="21"
                  height="20"
                  viewBox="0 0 21 20"
                  fill="none"
                >
                  <path
                    d="M18.5882 10.59L12.7557 16.4226C12.5932 16.5851 12.3798 16.6667 12.1665 16.6667C11.9532 16.6667 11.7398 16.5851 11.5773 16.4226C11.2515 16.0967 11.2515 15.57 11.5773 15.2442L15.9881 10.8334H2.99984C2.539 10.8334 2.1665 10.46 2.1665 10C2.1665 9.54003 2.539 9.1667 2.99984 9.1667H15.9881L11.5773 4.75589C11.2515 4.43006 11.2515 3.90334 11.5773 3.5775C11.9031 3.25167 12.4299 3.25167 12.7557 3.5775L18.5882 9.41002C18.6657 9.48752 18.7266 9.57913 18.7691 9.68163C18.8532 9.88496 18.8532 10.1151 18.7691 10.3184C18.7266 10.4209 18.6657 10.5125 18.5882 10.59Z"
                    fill="white"
                  />
                </svg>
              </button>
              <div className="relative group">
                <button
                  className="relative flex justify-center items-center gap-[8px] select-none rounded-lg bg-[#164ED4] p-[16px] max-w-[222px] w-full font-sans text-xs font-bold uppercase text-white shadow-md shadow-blue-500/20 transition-all hover:shadow-lg hover:shadow-blue-500/40 focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
                  type="button"
                  onClick={() => navigate("/app/autoApply")}
                >
                  <span className="absolute -top-1 -right-1 flex h-3 w-3">
                    <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-green-600 opacity-75"></span>
                    <span className="relative inline-flex rounded-full h-3 w-3 bg-green-700"></span>
                  </span>
                  Auto Apply Jobs
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="21"
                    height="20"
                    viewBox="0 0 21 20"
                    fill="none"
                  >
                    <path
                      d="M18.5882 10.59L12.7557 16.4226C12.5932 16.5851 12.3798 16.6667 12.1665 16.6667C11.9532 16.6667 11.7398 16.5851 11.5773 16.4226C11.2515 16.0967 11.2515 15.57 11.5773 15.2442L15.9881 10.8334H2.99984C2.539 10.8334 2.1665 10.46 2.1665 10C2.1665 9.54003 2.539 9.1667 2.99984 9.1667H15.9881L11.5773 4.75589C11.2515 4.43006 11.2515 3.90334 11.5773 3.5775C11.9031 3.25167 12.4299 3.25167 12.7557 3.5775L18.5882 9.41002C18.6657 9.48752 18.7266 9.57913 18.7691 9.68163C18.8532 9.88496 18.8532 10.1151 18.7691 10.3184C18.7266 10.4209 18.6657 10.5125 18.5882 10.59Z"
                      fill="white"
                    />
                  </svg>
                </button>
                <div className="hidden lg:block absolute top-1/2 left-full ml-4 transform -translate-y-1/2 w-64 p-4 bg-white text-black rounded-lg shadow-lg opacity-0 group-hover:opacity-100 transition-opacity duration-300 z-10">
                  <Typography variant="h6" className="font-bold mb-2">
                    Tired of Job applications?
                  </Typography>
                  <Typography className="text-sm mb-2">
                    We will search and apply for jobs you want.
                  </Typography>
                </div>
              </div>
            </div>

            <div
              className="logo-container flex items-center
          justify-center gap-[16px] md:gap-[32px] pt-0 md:pt-[32px] flex-wrap"
            >
              <div className="logo p-[10px] md:p-[20px]">
                <img src={google} alt="google" />
              </div>
              <div className="logo p-[10px] md:p-[20px]">
                <img src={netflix} alt="netflix" />
              </div>
              <div className="logo p-[10px] md:p-[20px]">
                <img src={amazon} alt="amazon" />
              </div>
              <div className="logo p-[10px] md:p-[20px]">
                <img src={palantir} alt="palantir" />
              </div>
              <div className="logo p-[10px] md:p-[20px]">
                <img src={anthrop} alt="anthrop" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <AutoApplyFeature />
    </>
  );
};

export default BannerHome;
