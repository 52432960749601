import React from "react";
import AutoApplyFeature from "./components/AutoApplyLandingPage/mainLandingPage";
import UserImpression from "../UserImpression";

function LoadAutoApplyPage() {
  return (
    <>
      <AutoApplyFeature />
      {/* <UserImpression /> */}
    </>
  );
}

export default LoadAutoApplyPage;
