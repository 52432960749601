import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import PersonalInformation from "./components/form/PersonalInformation";
import JobPreferences from "./components/form/JobPreferences";
import AdditionalDetails from "./components/form/AdditionalDetails";
import FileUploads from "./components/form/FileUploads";
// import Supabase from "../../Utils/supabase"; // Commented out for testing
import { toast } from "react-toastify";
import ProgressBar from "./progressbar";
import { getUserByEmail } from "../../services/auth";
import Supabase from "../../Utils/supabase";
import { useSelector } from "react-redux";
import { pingServer } from "../../services/pingApplicationServer";
import { createTransaction } from "../../supabaseServices/transaction";

const JobApplicationForm = ({ amount, paymentMethod }) => {
  const authState = useSelector((state) => state.auth);
  const location = useLocation();
  const { state, search } = location;
  const { paymentIntent, subscription_id, jobs, paymentDuration } = state || {
    paymentIntent: {},
    subscription_id: null,
    jobs: 0,
    paymentDuration: "",
  };

  const [formData, setFormData] = useState({
    email: "",
    jobTitles: "",
    preferred_industries: "Computer Software",
    preferred_job_titles: "",
    preffered_locations: "",
    preferred_job_types: "Full-Time",
    minimum_salary: 100000,
    additionalInformation: "",
    numberOfJobs: "",
    resume: null,
    coverLetter: null,
  });
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    if (files) {
      if (files[0]?.size > 3 * 1024 * 1024) {
        toast.error("File size must be less than 3MB");
        return;
      }
      const fileURL = URL.createObjectURL(files[0]);
      setFormData((prev) => ({
        ...prev,
        [name]: files[0],
        [`${name}_preview`]: fileURL,
      }));
    } else {
      setFormData((prev) => ({ ...prev, [name]: value }));
    }
  };

  const handleChangePreference = (value, label) => {
    setFormData((prev) => ({ ...prev, [label]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const user = await getUserByEmail(authState?.user?.email);

      const { data: resumeData, error: resumeError } = await Supabase.storage
        .from("resumes")
        .upload(
          Math.floor(Math.random() * 1000000) + formData?.resume?.name,
          formData.resume
        );
      if (resumeError) throw resumeError;
      let coverLetterPath = "";

      if (formData.coverLetter) {
        const { data: coverLetterData, error: coverLetterError } =
          await Supabase.storage
            .from("cover_letters")
            .upload(
              Math.floor(Math.random() * 1000000) + formData.coverLetter.name,
              formData.coverLetter
            );
        if (coverLetterError) throw coverLetterError;
        coverLetterPath = coverLetterData;
      }

      const storageRoute = process.env.REACT_APP_STORAGE_ROUTE;

      const date = new Date();
      date.setMonth(
        paymentDuration == "3 months"
          ? date.getMonth() + 3
          : date.getMonth() + 1
      ); // Add 1 month to the current date
      const utcDate = date.toISOString();
      const payload = {
        transaction_id: paymentIntent?.id,
        payment_status: "completed",
        plan_type: paymentDuration == "one-time" ? "one-time" : "subscribe",
        points_earned: jobs,
        points_used: 0,
        points_expiry_date: utcDate,
        is_active: true,
        user_id: user?.length > 0 ? user[0]?.id : null,
        subscription_id,
        auto_applied: true,
      };
      const response = await createTransaction(payload);

      if (response?.status != 201) {
        toast.error("Failed to add transaction!");
        return;
      }

      const { data, status, error } = await Supabase.from("JobApplications")
        .insert([
          {
            user_id: authState?.user?.user_id,
            email: formData.email,
            title: formData.jobTitles,
            number_of_jobs: formData.numberOfJobs,
            preferred_job_titles: formData.preferred_job_titles,
            preferred_industries: formData.preferred_industries,
            preffered_locations: formData.preffered_locations,
            preferred_job_types: formData.preferred_job_types,
            minimum_salary: formData.minimum_salary,
            additional_information: formData.additionalInformation,
            resume_url: storageRoute + "/" + resumeData?.fullPath || "",
            cover_letter_url:
              coverLetterPath == ""
                ? ""
                : storageRoute + "/" + coverLetterPath?.fullPath,
            transaction_id: response.data[0]?.id,
          },
        ])
        .select();

      if (error) throw error;

      if (status == 201) {
        const resp = await pingServer(
          { ...formData, transaction_id: response.data[0]?.id },
          storageRoute + "/" + resumeData?.fullPath,
          coverLetterPath == ""
            ? ""
            : storageRoute + "/" + coverLetterPath?.fullPath,
          user?.length > 0 ? user[0]?.id : null
        );

        if (resp?.status != 201) {
          throw new Error("Failed to ping Django server");
        }
      }

      // Temporary success message for testing
      toast.success("Application submitted successfully!");

      // Navigate to receipt page for testing purposes
      navigate("/app/receipt", {
        state: { formData, amount: paymentIntent?.amount / 100, paymentMethod },
      });
    } catch (error) {
      toast.error("Failed to submit application.");
    }
  };

  return (
    <div className="container mx-auto p-4">
      <ProgressBar currentStep={2} />
      <h1 className="text-2xl font-bold mb-4">Apply for Jobs</h1>
      <form onSubmit={handleSubmit} className="space-y-4">
        <PersonalInformation formData={formData} handleChange={handleChange} />
        <JobPreferences
          formData={formData}
          handleChange={handleChangePreference}
        />
        <AdditionalDetails formData={formData} handleChange={handleChange} />
        <FileUploads formData={formData} handleChange={handleChange} />
        <button
          type="submit"
          className="mt-4 px-14 py-2 !bg-[#164ED4] text-white rounded-md"
        >
          Submit
        </button>
      </form>
    </div>
  );
};

export default JobApplicationForm;
