import React, { useEffect, useRef, useState } from "react";
import "./JobList.css";
import JobCard from "../../components/JobCard";
import JobCardSkeleton from "../../components/JobCard/JobSkeleton";
import NoCompanyJobFound from "../../components/CompanyStatistic/NoCompanyJobFound";
import { Tooltip, Dialog, DialogContent, Button } from "@mui/material";
import InitialDialog from "./InitialDialog";
import SubscriptionOptionsDialog from "./SubscriptionOptionsDialog";
import PaymentOptionsDialog from "./PaymentOptionsDialog";
import { useDispatch, useSelector } from "react-redux";
import {
  clearCartAction,
  jobsFromSupabase,
  removeJobInCartAction,
} from "../../redux/actions/cart";
import { toast } from "react-toastify";
import { getJobsFromCart } from "../../supabaseServices/cart";
import { useNavigate } from "react-router-dom";
import JobCartFAQ from "./JobCartFaq";

const MIN_JOBS_FOR_CHECKOUT = 10;

const CartJobList = ({ updateCartCount }) => {
  const [jobs, setJobs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [step, setStep] = useState(0); // Step 0: Selection, Step 1: Subscription Options, Step 2: Payment
  const [planType, setPlanType] = useState(""); // To keep track of selected subscription type
  const cartReducer = useSelector((state) => state.cart);
  const authReducer = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (cartReducer?.cartJobs) {
      setJobs([...cartReducer?.cartJobs]);
      setLoading(false);
    }
  }, [cartReducer.cartJobs]);

  const calculatePrice = (numJobs) => {
    if (numJobs <= 10) return 30;
    if (numJobs <= 20) return 50;
    if (numJobs <= 40) return 90;
    return 90 + (numJobs - 40) * 2.25; // Beyond 40 jobs, additional cost per job
  };

  const removeJobFromCart = async (jobId) => {
    await removeJobInCartAction(jobId, dispatch, authReducer);

    toast.success("Job removed from cart", {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const clearCart = () => {
    setJobs([]);
    clearCartAction(authReducer?.user?.id, dispatch);
  };

  const handleClickOpen = () => {
    if (jobs.length >= MIN_JOBS_FOR_CHECKOUT) {
      if (authReducer?.user?.id) {
        navigate(`/app/checkout?price=${price}`);
      } else {
        navigate("/auth", { state: { checkout_process: true } });
      }
    }
  };

  const handleClose = () => {
    setOpen(false);
    setStep(0); // Reset to the initial step when closing
    setPlanType(""); // Reset the plan type
  };

  const handleContinue = (type) => {
    if (type === "one-time") {
      setPlanType("one-time");
      setStep(2); // Move to payment step
    } else if (type === "subscribe") {
      setStep(1); // Move to subscription options step
    }
  };

  const handleSubscriptionContinue = (subscriptionType) => {
    setPlanType(subscriptionType);
    setStep(2); // Move to payment step
  };

  const handleBack = () => {
    if (step === 1) {
      setStep(0); // Move back to the initial step
    } else if (step === 2) {
      setStep(1); // Move back to subscription options step
    }
  };

  const price =
    jobs.length < MIN_JOBS_FOR_CHECKOUT ? 0 : calculatePrice(jobs.length);

  return (
    <div className="jobs-container mx-2 max-w-[1324px] sm:m-auto">
      <div className="p-4 w-full">
        <div className="flex justify-between items-center gap-2 flex-wrap pb-[8px] border-b border-[#E9E9EA] mb-[1.25%]">
          <div className="flex items-center gap-[8px]">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 18 18"
              fill="none"
            >
              {/* Your SVG Path */}
            </svg>
            <p className="all-jobs-title">
              Jobs On Your Cart - We Will Auto-Apply To these jobs (Please add
              at least 10 jobs)
            </p>
          </div>
          <div className="flex items-center gap-[8px]">
            <p className="text-lg font-bold">${price}</p>
            <Tooltip
              title={`You need at least ${MIN_JOBS_FOR_CHECKOUT} jobs in your cart to proceed to checkout`}
              placement="top"
              disableHoverListener={jobs.length >= MIN_JOBS_FOR_CHECKOUT}
            >
              <span>
                <Button
                  variant="contained"
                  disabled={
                    jobs.length < MIN_JOBS_FOR_CHECKOUT
                    //  ||
                    // !authReducer?.user?.id
                  }
                  onClick={handleClickOpen}
                  className=""
                >
                  Checkout
                </Button>
              </span>
            </Tooltip>
            <Button variant="contained" color="error" onClick={clearCart}>
              Clear Cart
            </Button>
            <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
              {step === 0 && <InitialDialog onContinue={handleContinue} />}
              {step === 1 && (
                <SubscriptionOptionsDialog
                  onBack={handleBack}
                  onContinue={handleSubscriptionContinue}
                />
              )}
              {step === 2 && (
                <PaymentOptionsDialog
                  onBack={handleBack}
                  planType={planType}
                  onClose={() => {
                    setOpen(false);
                    setStep(0);
                  }}
                />
              )}
            </Dialog>
          </div>
        </div>

        <div className="grid grid-cols-1 gap-8 mb-4 lg:grid-cols-2 xl:grid-cols-3">
          {loading ? (
            Array.from(new Array(12)).map((_, index) => (
              <div key={index} className="w-full md:w-1/2 lg:w-1/3 p-2">
                <JobCardSkeleton />
              </div>
            ))
          ) : jobs.length > 0 ? (
            jobs.map((job, index) => (
              <div key={index} className="flex flex-col h-full">
                <div className="h-120 overflow-hidden">
                  <JobCard
                    job={job}
                    showButtons={false}
                    showLink={false}
                    isInCart={true}
                    updateCartCount={updateCartCount}
                    onRemoveFromCart={removeJobFromCart}
                  />
                </div>
                <div className="flex-grow"></div>
              </div>
            ))
          ) : (
            <div className="col-span-full flex justify-center items-center w-full">
              <div className="text-center">
                <NoCompanyJobFound
                  text="You have no job in your cart. Add Jobs to cart to see them here. If you would like us to handle the entire job application process, visit "
                  link="/app/autoApply"
                  linkText="our Auto-Apply service"
                />
              </div>
            </div>
          )}
        </div>
        <div className="flex flex-col items-center justify-center">
          <JobCartFAQ />
        </div>
      </div>
    </div>
  );
};

export default CartJobList;
