import React, { useEffect, useRef, useState } from "react";
import { Button, Spinner, Typography } from "@material-tailwind/react";
import Bookmark from "../../assets/icons/bookmark.png";
import parse from "html-react-parser";
import "./jobDetails.css";
import { getSelectedJob, saveJob } from "../../services/auth";
import { useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import { fetchJobById, SaveJobOnBackend } from "../../services/jobs";

const JobDetails = ({ open, handleClose, data, isInCart, isSaved = false }) => {
  const cleanJobDescription = (data?.job_description || "")
    .replace(/[`'()]/g, "")
    .trim();

  let alreadySaved = useRef(false);

  const authState = useSelector((state) => state.auth);

  const [jobData, setJobData] = useState({});

  useEffect(() => {
    if (open) {
      fetchSelectedJobFromSupabase();
    }
  }, [open]);
  useEffect(() => {
    if (data) fetchJobData();
  }, [data]);

  const fetchJobData = async () => {
    const response = await fetchJobById(data);
    if (response?.status == 200) {
      setJobData({
        ...response?.data,
        location: [
          response?.data?.job_city,
          response?.data?.job_state,
          response?.data?.job_country ?? "",
        ]
          .filter(Boolean)
          .join(", "),
      });
    }
  };

  const fetchSelectedJobFromSupabase = async () => {
    const response = await getSelectedJob({
      job_id: data?.job_id,
      user_id: authState.user?.id,
    });
    if (response?.length) alreadySaved.current = true;
  };

  const handleSaveJob = async (job) => {
    if (alreadySaved.current) {
      toast.info("Job has already been saved!");
      return;
    }
    const payload = {
      ...job,
      default_id: job?.id,
      user_id: authState.user?.id,
    };
    delete payload["id"];
    const response = await saveJob(payload);
    if (response?.code && authState.user?.id) toast.error(response?.message);
    else if (response?.code && authState.user?.id == undefined)
      toast.error("Log in to save this job");
    else {
      SaveJobOnBackend(job?.job_id);
      toast.success("Job has been saved!");
      alreadySaved.current = true;
    }
  };

  return (
    <>
      {open && <div className="jobDetailsOverlay" onClick={handleClose} />}
      {open && (
        <div className="jobDetailsCard">
          <ToastContainer />
          <div className="jobDetailsCardHeader justify-end">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="currentColor"
              className="h-5 w-5"
              onClick={handleClose}
            >
              <path
                fillRule="evenodd"
                d="M5.47 5.47a.75.75 0 011.06 0L12 10.94l5.47-5.47a.75.75 0 111.06 1.06L13.06 12l5.47 5.47a.75.75 0 11-1.06 1.06L12 13.06l-5.47 5.47a.75.75 0 01-1.06-1.06L10.94 12 5.47 6.53a.75.75 0 010-1.06z"
                clipRule="evenodd"
              />
            </svg>
          </div>
          {Object.keys(jobData)?.length > 0 ? (
            <div className="jobDetailsCardBody">
              <div className="h-[80vh] overflow-scroll px-4 md:px-10 job-detail-container pb-4 md:pb-10">
                <div className="flex items-center justify-between mb-4 md:mb-8">
                  <div className="w-full">
                    <div className="flex items-center justify-between">
                      <Typography variant="h5" className="jobDetail-title">
                        {jobData?.job_name}
                      </Typography>
                      {!isSaved && (
                        <img
                          src={Bookmark}
                          alt="bookmark"
                          width={35}
                          className="cursor-pointer"
                          onClick={() => handleSaveJob(jobData)}
                        />
                      )}
                    </div>
                  </div>
                </div>

                <div className="mb-4 md:mb-8 mt-8">
                  {jobData?.min_salary !== null &&
                  jobData?.max_salary !== null ? (
                    <Typography>
                      💵 Salary:{" "}
                      <span>
                        ${jobData.min_salary} - ${jobData.max_salary}
                      </span>
                    </Typography>
                  ) : (
                    <>
                      {jobData?.min_salary !== null && (
                        <Typography>
                          💵 Min Salary: <span>${jobData.min_salary}</span>
                        </Typography>
                      )}
                      {jobData?.max_salary !== null && (
                        <Typography>
                          💵 Max Salary: <span>${jobData.max_salary}</span>
                        </Typography>
                      )}
                    </>
                  )}
                </div>

                <div className="mb-4 md:mb-8 mt-8">
                  🏢 Office Location: {jobData?.location}
                  <Typography></Typography>
                </div>

                <div className="mb-4 md:mb-8 mt-8">
                  🧳 Career Level: {jobData?.job_yoe}
                  <Typography></Typography>
                </div>

                <div className="mb-4 md:mb-8 mt-8">
                  🖖 Job Category: {jobData?.job_general_category}
                </div>

                <div className="flex flex-col gap-4">
                  <div>
                    <div className="jobDetail-heading mb-3 mt-4">
                      Job Description
                    </div>
                    <div>
                      {parse(
                        jobData?.job_description
                          .replace(/[`'()]/g, "")
                          .trim() ?? ""
                      )}
                    </div>
                  </div>
                </div>

                {!isInCart && (
                  <div className="flex justify-center mt-6">
                    <Button
                      className="jobCardButtons applyButton capitalize"
                      onClick={() => {
                        if (jobData?.job_apply_link) {
                          window.open(jobData?.job_apply_link, "_blank");
                        }
                      }}
                    >
                      Apply
                    </Button>
                  </div>
                )}
              </div>
            </div>
          ) : (
            <div className="flex items-center justify-center h-full">
              <Spinner className="h-12 w-12" color="indigo" />
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default JobDetails;
