import React from "react";
import { Stepper, Step, Button } from "@material-tailwind/react";
import "./formStepper.css";

const FormStepper = ({
  activeStep,
  setIsFirstStep,
  setIsLastStep,
  setActiveStep,
}) => {
  return (
    <div className="w-full py-4 px-8">
      <Stepper
        activeStep={activeStep}
        isLastStep={(value) => setIsLastStep(value)}
        isFirstStep={(value) => setIsFirstStep(value)}
        className="stepper-container"
      >
        <Step className={activeStep >= 0 && "!bg-[#164ED4] text-white"}>1</Step>
        <Step className={activeStep >= 1 && "!bg-[#164ED4] text-white"}>2</Step>
        <Step className={activeStep >= 2 && "!bg-[#164ED4] text-white"}>3</Step>
        <Step className={activeStep >= 3 && "!bg-[#164ED4] text-white"}>4</Step>
      </Stepper>
    </div>
  );
};

export default FormStepper;
