import React, { useEffect, useState } from "react";
import {
  Tabs,
  TabsHeader,
  TabsBody,
  Tab,
  TabPanel,
  Spinner,
} from "@material-tailwind/react";
import JobCard from "../JobCard";
import {
  getSavedJobsByUserId,
  getAutoAppliedJobsByUserId,
  getUserByEmail,
  deleteSavedJobsByUserId,
} from "../../services/auth";
import { useSelector } from "react-redux";
import "./customTabs.css";
import AutoApplyFeature from "../JobApplications/components/AutoApplyLandingPage/mainLandingPage";
import JobApplicationsTable from "./jobApplications";
import CustomTable from "../CustomTable";
import {
  getAutoAppliedTransactions,
  getTrackJobsByUserId,
  getTransactionsByUserId,
  updateTransactionStatus,
} from "../../supabaseServices/transaction";
import { cancelSubscription } from "../../services/checkoutService";
import TrackJobsTable from "../TrackJobsTable";

const CustomTabs = ({ data, activeTab, setActiveTab }) => {
  const authState = useSelector((state) => state.auth);
  const [savedJobs, setSavedJobs] = useState([]);
  const [autoAppliedJobs, setAutoAppliedJobs] = useState([]);
  const [transactions, setTransactions] = useState([]);
  const [trackJobs, setTrackJobs] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    if (activeTab === "saved_jobs" && Object.keys(authState?.user)?.length) {
      fetchSavedJobs();
    } else if (
      activeTab === "autoApplied" &&
      Object.keys(authState?.user)?.length
    ) {
      fetchAutoAppliedJobs();
    } else if (
      activeTab === "transaction" &&
      Object.keys(authState?.user)?.length
    ) {
      fetchTransactions();
    } else if (activeTab == "track") fetchTrackJobs();
    else {
      setSavedJobs([]);
      setAutoAppliedJobs([]);
      setTransactions([]);
      setTrackJobs([]);
      setLoading(false);
    }
  }, [activeTab, authState?.user]);

  const fetchTrackJobs = async () => {
    const user = await getUserByEmail(authState?.user?.email);
    const response = await getTrackJobsByUserId(
      user?.length > 0 ? user[0]?.id : null
    );

    if (response?.length) {
      setTrackJobs(response);
    }
    setLoading(false);
  };

  const fetchSavedJobs = async () => {
    const response = await getSavedJobsByUserId(authState?.user?.id);
    if (response?.length) {
      setSavedJobs(response.reverse());
    } else setSavedJobs([]);
    setLoading(false);
  };

  const deleteSavedJobs = async (id) => {
    const response = await deleteSavedJobsByUserId(id);
    if (response?.status == 204) fetchSavedJobs();
  };

  const fetchAutoAppliedJobs = async () => {
    const getIds = await getAutoAppliedTransactions(authState?.user?.user_id);

    const response = await getAutoAppliedJobsByUserId(
      authState?.user?.user_id,
      getIds?.map((transaction) => transaction?.id)
    );

    const groupedData = getIds?.map((transaction) => {
      return {
        ...transaction,
        items: response?.filter(
          (item) => item.transaction_id == transaction?.id
        ),
      };
    });

    if (groupedData?.length) {
      setAutoAppliedJobs(groupedData);
    }
    setLoading(false);
  };

  const fetchTransactions = async () => {
    const user = await getUserByEmail(authState?.user?.email);
    const response = await getTransactionsByUserId(
      user?.length > 0 ? user[0]?.id : null
    );

    if (response?.length) {
      setTransactions(response);
    }
    setLoading(false);
  };

  const cancelSubscriptionWithId = async (transaction) => {
    if (transaction?.subscription_id) {
      const response = await cancelSubscription(transaction?.subscription_id);
      if (response?.message == "Subscription successfully terminated") {
        await updateTransactionStatus(transaction?.id, !transaction?.is_active);
        fetchTransactions();
      }
    }
  };

  return (
    <Tabs value={activeTab} className="">
      <TabsHeader
        className="min-w-[600px] rounded-none border-b border-blue-gray-50 bg-transparent p-0 gap-2"
        indicatorProps={{
          className:
            "bg-transparent border-b-2 border-[#164ED4] shadow-none rounded-none",
        }}
      >
        {data.map(({ label, value, icon }) => (
          <Tab
            key={value}
            value={value}
            onClick={() => setActiveTab(value)}
            className={activeTab === value ? "w-fit text-[#164ED4]" : "w-fit"}
          >
            <div className="flex gap-2 items-center">
              <span>{icon}</span>
              <span className="hidden md:block"> {label}</span>
            </div>
          </Tab>
        ))}
      </TabsHeader>
      <TabsBody className="overflow-x-scroll">
        {data.map(({ value }) => (
          <TabPanel key={value} value={value} className="p-0">
            <div
              className={`flex gap-8 flex-wrap justify-center lg:justify-start tab-body-container ${
                value === "autoApplied" ? "autoAppliedJobsContainer" : ""
              }`}
            >
              {loading ? (
                <div className="w-full flex justify-center py-4">
                  <Spinner color="indigo" className="h-10 w-10" />
                </div>
              ) : value === "saved_jobs" && savedJobs.length > 0 ? (
                savedJobs.map((job, index) => (
                  <div key={index} className="min-w-[320px] max-w-[480px]">
                    <JobCard
                      key={index}
                      job={job}
                      deleteJob={deleteSavedJobs}
                      isSaved={true}
                    />
                  </div>
                ))
              ) : value === "autoApplied" && autoAppliedJobs.length > 0 ? (
                <div className="pt-10 w-full">
                  <JobApplicationsTable data={autoAppliedJobs} />
                </div>
              ) : value === "autoApplied" && autoAppliedJobs.length === 0 ? (
                <AutoApplyFeature />
              ) : value == "transaction" ? (
                <div className="mt-4 w-full">
                  <CustomTable
                    data={transactions}
                    cancelSubscription={cancelSubscriptionWithId}
                  />
                </div>
              ) : value == "track" ? (
                <div className="mt-4 w-full">
                  <TrackJobsTable data={trackJobs} />
                </div>
              ) : (
                <div className="w-full text-center mt-5">No Data Found</div>
              )}
            </div>
          </TabPanel>
        ))}
      </TabsBody>
    </Tabs>
  );
};

export default CustomTabs;
