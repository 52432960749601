let requestOptions = {
  method: "POST",
  headers: {
    "Content-Type": "application/json",
    Authorization:
      // "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZS1kZW1vIiwicm9sZSI6ImFub24iLCJleHAiOjE5ODM4MTI5OTZ9.CRXP1A7WOeoJeXxjNni43kdQwgnWNReilDMblYTn_I0", // local
      "Bearer " + process.env.REACT_APP_SUPABASE_KEY,
  },
};

let baseUrl =
  // "http://127.0.0.1:54321/functions/v1";
  process.env.REACT_APP_SUPABASE_URL + "/functions/v1";

export const findOrCreateCustomerForPayment = async (email, name) => {
  // Create the PaymentIntent and obtain clientSecret from your server endpoint

  const options = { ...requestOptions, body: JSON.stringify({ email, name }) };
  const res = await fetch(`${baseUrl}/find_or_create_customer_id`, options);
  const extractData = await res.json();
  return extractData?.customerId;
};

export const createPaymentIntent = async (amount, customerId) => {
  // Create the PaymentIntent and obtain clientSecret from your server endpoint

  const options = {
    ...requestOptions,
    body: JSON.stringify({ amount, customerId }),
  };
  const res = await fetch(`${baseUrl}/create_payment_intent`, options);

  const extractData = await res.json();

  return extractData;
};

export const createSubscriptionPaymentIntent = async (priceId, customerId) => {
  // Create the PaymentIntent and obtain clientSecret from your server endpoint

  const options = {
    ...requestOptions,
    body: JSON.stringify({ customerId, priceId }),
  };
  const res = await fetch(
    `${baseUrl}/create_subscription_payment_intent`,
    options
  );
  const extractData = await res.json();
  return extractData;
};

export const cancelSubscription = async (subscription_id) => {
  const options = {
    ...requestOptions,
    body: JSON.stringify({ subscriptionId: subscription_id }),
  };
  const res = await fetch(`${baseUrl}/delete_subscription`, options);
  const extractData = await res.json();
  return extractData;
};
