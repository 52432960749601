import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faTwitter,
  faLinkedin,
  faWhatsapp,
  faReddit,
} from "@fortawesome/free-brands-svg-icons";
import { faXTwitter } from "@fortawesome/free-brands-svg-icons";
import CustomTooltip from "../../components/JobCard/customTooltip"; // Import the CustomTooltip
import "./SocialSidebar.css";

const SocialSidebar = ({ title, url }) => {
  const encodedTitle = encodeURIComponent(title);
  const encodedUrl = encodeURIComponent(url);

  return (
    <div className="social-sidebar">
      {/* Facebook */}
      <CustomTooltip title="Share to Facebook">
        <a
          aria-label="Share on Facebook"
          href={`https://www.facebook.com/sharer/sharer.php?u=${encodedUrl}`}
          target="_blank"
          rel="noopener noreferrer"
          className="social-icon"
        >
          <FontAwesomeIcon icon={faFacebook} color="#1877F2" />
        </a>
      </CustomTooltip>

      {/* LinkedIn */}
      <CustomTooltip title="Share to LinkedIn">
        <a
          aria-label="Share on LinkedIn"
          href={`https://www.linkedin.com/sharing/share-offsite/?url=${encodedUrl}`}
          target="_blank"
          rel="noopener noreferrer"
          className="social-icon"
        >
          <FontAwesomeIcon icon={faLinkedin} color="#0077B5" />
        </a>
      </CustomTooltip>

      {/* Reddit */}
      <CustomTooltip title="Share to Reddit">
        <a
          aria-label="Share on Reddit"
          href={`https://www.reddit.com/submit?url=${encodedUrl}&title=${encodedTitle}`}
          target="_blank"
          rel="noopener noreferrer"
          className="social-icon"
        >
          <FontAwesomeIcon icon={faReddit} color="#FF4500" />
        </a>
      </CustomTooltip>

      {/* WhatsApp */}
      <CustomTooltip title="Share to WhatsApp">
        <a
          aria-label="Share on WhatsApp"
          href={`https://wa.me/?text=${encodedTitle}%20${encodedUrl}`}
          target="_blank"
          rel="noopener noreferrer"
          className="social-icon"
        >
          <FontAwesomeIcon icon={faWhatsapp} color="#25D366" />
        </a>
      </CustomTooltip>

      {/* X (formerly Twitter) */}
      <CustomTooltip title="Share to X">
        <a
          aria-label="Share on X"
          href={`https://x.com/intent/tweet?url=${encodedUrl}&text=${encodedTitle}`}
          target="_blank"
          rel="noopener noreferrer"
          className="social-icon"
        >
          <FontAwesomeIcon icon={faXTwitter} color="#000000" />
        </a>
      </CustomTooltip>
    </div>
  );
};

export default SocialSidebar;
