import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  useLocation,
} from "react-router-dom";
import AuthRoutes from "./routes/AuthRoutes";
import Header from "./components/Header";
import Footer from "./components/Footer";
import AppRoutes from "./routes/AppRoutes";
import Cookies from "js-cookie";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function App() {
  const location = useLocation();
  const [cartCount, setCartCount] = useState(0);

  useEffect(() => {
    updateCartCount();
  }, []);

  const updateCartCount = () => {
    const cart = JSON.parse(localStorage.getItem("cart")) || [];
    setCartCount(cart.length);
  };

  const checkProtectedRoute = () => {
    return "/app/home";
  };

  return (
    <div>
      <ToastContainer
        position="top-right"
        autoClose={300}
        hideProgressBar={false}
        closeOnClick
        rtl={false}
        theme="light"
      />
      <Header cartCount={cartCount} />
      <Routes>
        <Route
          path="/"
          element={<Navigate replace to={checkProtectedRoute()} />}
        />
        <Route path="/auth/*" element={<AuthRoutes />} />
        <Route
          path="/app/*"
          element={<AppRoutes updateCartCount={updateCartCount} />}
        />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
