import { Button } from "@material-tailwind/react";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { createUser, signUpUser, insertToUsers } from "../../services/auth";
import { toast } from "react-toastify";
import TermsAndConditions from "../../components/TermsAndConditions";

const SignUp = () => {
  const navigate = useNavigate();
  const [userData, setUserData] = useState({
    email: "",
    password: "",
    firstName: "",
    lastName: "",
  });
  const [isTncOpen, setTncOpen] = useState(false);

  const handleSubmit = async () => {
    const response = await signUpUser(userData);
    if (response?.user) {
      await insertToUsers(userData);
      toast.success(
        "Your account has been successfully created. Please check your email to verify your account!"
      );
      navigate("/auth");
    } else toast.error(response?.msg || response?.message);
  };

  const handleTncOpen = () => {
    setTncOpen(true);
  };

  const handleTncClose = () => {
    setTncOpen(false);
  };

  return (
    <div className="px-5 py-20 relative flex flex-col items-center justify-center text-gray-700 bg-transparent shadow-none rounded-xl bg-clip-border">
      <h4 className="block font-sans text-3xl antialiased font-semibold leading-snug tracking-normal text-blue-gray-900">
        Sign Up
      </h4>
      <form className="max-w-screen-lg mt-8 mb-2 max-w-80 sm:max-w-96">
        <div className="flex flex-col gap-6 mb-1">
          <h6 className="block -mb-3 font-sans text-base antialiased font-semibold leading-relaxed tracking-normal text-blue-gray-900">
            First Name
          </h6>
          <div className="relative h-11 w-full min-w-[200px]">
            <input
              placeholder="Input First Name"
              className="peer h-full w-full rounded-md border border-blue-gray-200 border-t-transparent !border-t-blue-gray-200 bg-transparent px-3 py-3 font-sans text-sm font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 focus:border-2 focus:border-gray-900 focus:border-t-transparent focus:!border-t-gray-900 focus:outline-0 disabled:border-0 disabled:bg-blue-gray-50"
              onChange={(e) =>
                setUserData({ ...userData, firstName: e.target?.value })
              }
              value={userData?.firstName}
            />
          </div>
          <h6 className="block -mb-3 font-sans text-base antialiased font-semibold leading-relaxed tracking-normal text-blue-gray-900">
            Last Name
          </h6>
          <div className="relative h-11 w-full min-w-[200px]">
            <input
              placeholder="Input Last Name"
              className="peer h-full w-full rounded-md border border-blue-gray-200 border-t-transparent !border-t-blue-gray-200 bg-transparent px-3 py-3 font-sans text-sm font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 focus:border-2 focus:border-gray-900 focus:border-t-transparent focus:!border-t-gray-900 focus:outline-0 disabled:border-0 disabled:bg-blue-gray-50"
              onChange={(e) =>
                setUserData({ ...userData, lastName: e.target?.value })
              }
              value={userData?.lastName}
            />
          </div>
          <h6 className="block -mb-3 font-sans text-base antialiased font-semibold leading-relaxed tracking-normal text-blue-gray-900">
            Your Email
          </h6>
          <div className="relative h-11 w-full min-w-[200px]">
            <input
              placeholder="Input Email"
              className="peer h-full w-full rounded-md border border-blue-gray-200 border-t-transparent !border-t-blue-gray-200 bg-transparent px-3 py-3 font-sans text-sm font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 focus:border-2 focus:border-gray-900 focus:border-t-transparent focus:!border-t-gray-900 focus:outline-0 disabled:border-0 disabled:bg-blue-gray-50"
              onChange={(e) =>
                setUserData({ ...userData, email: e.target?.value })
              }
              value={userData?.email}
            />
          </div>
          <h6 className="block -mb-3 font-sans text-base antialiased font-semibold leading-relaxed tracking-normal text-blue-gray-900">
            Password
          </h6>
          <div className="relative h-11 w-full min-w-[200px]">
            <input
              placeholder="Input Password"
              type="password"
              className="peer h-full w-full rounded-md border border-blue-gray-200 border-t-transparent !border-t-blue-gray-200 bg-transparent px-3 py-3 font-sans text-sm font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 focus:border-2 focus:border-gray-900 focus:border-t-transparent focus:!border-t-gray-900 focus:outline-0 disabled:border-0 disabled:bg-blue-gray-50"
              onChange={(e) =>
                setUserData({ ...userData, password: e.target?.value })
              }
              value={userData?.password}
            />
          </div>
        </div>
        <div className="inline-flex items-center">
          <label
            className="relative -ml-2.5 flex cursor-pointer items-center rounded-full p-3"
            htmlFor="remember"
          >
            <input
              type="checkbox"
              className="before:content[''] peer relative h-5 w-5 cursor-pointer appearance-none rounded-md border border-blue-gray-200 transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-12 before:w-12 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-blue-gray-500 before:opacity-0 before:transition-opacity checked:border-gray-900 checked:bg-gray-900 checked:before:bg-gray-900 hover:before:opacity-10"
              id="remember"
            />
            <span className="absolute text-white transition-opacity opacity-0 pointer-events-none top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 peer-checked:opacity-100">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-3.5 w-3.5"
                viewBox="0 0 20 20"
                fill="currentColor"
                stroke="currentColor"
                strokeWidth="1"
              >
                <path
                  fillRule="evenodd"
                  d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                  clipRule="evenodd"
                ></path>
              </svg>
            </span>
          </label>
          <label
            className="mt-px font-light text-gray-700 cursor-pointer select-none"
            htmlFor="remember"
          >
            <p className="flex items-center font-sans text-sm antialiased font-normal leading-normal text-gray-700">
              I agree to the
              <a
                href="#"
                className="font-medium text-[#164ED4]  transition-colors hover:text-gray-900"
                onClick={(e) => {
                  e.preventDefault();
                  handleTncOpen();
                }}
              >
                &nbsp;Terms and Conditions
              </a>
            </p>
          </label>
        </div>
        <Button
          className="w-full text-center text-[#164ED4] border border-[#164ED4] bg-white hover:bg-[#164ED4] hover:text-white"
          onClick={handleSubmit}
        >
          {" "}
          Sign Up
        </Button>
        <p className="block mt-4 font-sans text-base antialiased font-normal leading-relaxed text-center text-gray-700">
          Already have an account?
          <a
            href="#"
            className="text-base font-medium text-[#164ED4] mx-2"
            onClick={() => navigate("/auth")}
          >
            Sign In
          </a>
        </p>
      </form>
      <TermsAndConditions open={isTncOpen} handleClose={handleTncClose} />
    </div>
  );
};

export default SignUp;
