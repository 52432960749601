import React from "react";
import JobCard from "../../JobCard";
import { Button } from "@material-tailwind/react";
import { useNavigate } from "react-router-dom";
import NoCompanyJobFound from "../NoCompanyJobFound";
import JobCardSkeleton from "../../JobCard/JobSkeleton";

const JobSection = ({ companyId, companyName, topJobs, loading }) => {
  const parameters = {
    title: companyName,
    location: "",
    duration: "Full-Time",
    department: "Engineering",
    level: "entry-level",
    experience: "Entry level",
    salary: 30000,
    industry: "Computer Software",
  };
  const navigate = useNavigate();

  return (
    <div className="py-[40px] md:py-[80px] space-y-[40px]">
      <h1 className="text-5xl font-bold text-black text-center">
        <span className="text-primary">Jobs</span> at {companyName}
      </h1>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
        {loading
          ? Array.from(new Array(3)).map((_, index) => (
              <div key={index} className="w-full md:w-1/2 lg:w-1/3 p-2">
                <JobCardSkeleton />
              </div>
            ))
          : topJobs?.length > 0
          ? topJobs.map((job, index) => (
              <div key={index} className="flex flex-col h-full">
                <div className="h-120 overflow-hidden">
                  <JobCard job={job} />
                </div>
                <div className="flex-grow"></div>
              </div>
            ))
          : !loading && (
              <div className="flex flex-col justify-center items-center space-y-4 mt-20 mb-20">
                <NoCompanyJobFound
                  text="No jobs found in our database. Visit"
                  link="/app/autoApply"
                  linkText="our Auto-Apply service"
                />
              </div>
            )}
      </div>
      <div className="flex justify-center items-center">
        <Button
          className="bg-transparent shadow-none border border-primary rounded-none text-primary text-sm font-normal"
          onClick={() => {
            navigate("/app/job-list", {
              state: {
                jobParameters: parameters,
                source: "companiesStatistics",
              },
            });
          }}
        >
          View more jobs at {companyName}
        </Button>
      </div>
    </div>
  );
};

export default JobSection;
